const MultiLocation = () => {
	return (
		<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.82301 1.31399C6.1112 0.78364 5.22862 0.469727 4.27276 0.469727C1.91307 0.469727 0 2.3828 0 4.74249C0 5.18535 0.0672373 5.61244 0.192321 6.01436C0.280218 6.29571 0.395911 6.56466 0.537147 6.81783L0.613024 6.94855C0.614902 6.95193 0.61678 6.95493 0.618658 6.95794L3.67485 11.6186L4.27276 12.5304L4.87067 11.6186L6.9482 8.45038L6.35029 7.53857L4.27276 10.7068L1.46824 6.42992L1.40658 6.32369C1.3008 6.13296 1.21362 5.92985 1.14696 5.71659C1.05164 5.41009 1 5.08336 1 4.74249C1 2.93508 2.46535 1.46973 4.27276 1.46973C5.0101 1.46973 5.6905 1.7136 6.23772 2.12508C6.40681 1.83573 6.60307 1.5642 6.82301 1.31399ZM14.8063 6.01436C14.9314 5.61244 14.9986 5.18535 14.9986 4.74249C14.9986 2.3828 13.0856 0.469727 10.7259 0.469727C8.36619 0.469727 6.45312 2.3828 6.45312 4.74249C6.45312 5.18535 6.52036 5.61244 6.64545 6.01436C6.73334 6.29571 6.84904 6.56466 6.99027 6.81783L7.06615 6.94855L7.06853 6.95266L7.07178 6.95794L10.7259 12.5304L14.38 6.95794L14.3822 6.95433L14.3856 6.94855L14.4615 6.81783C14.6027 6.56466 14.7184 6.29571 14.8063 6.01436ZM13.1543 4.74267C13.1543 6.084 12.0669 7.17137 10.7256 7.17137C9.38424 7.17137 8.29688 6.084 8.29688 4.74267C8.29688 3.40133 9.38424 2.31396 10.7256 2.31396C12.0669 2.31396 13.1543 3.40133 13.1543 4.74267ZM4.27245 7.17137C4.88159 7.17137 5.43835 6.94712 5.86468 6.57667C5.82892 6.48201 5.79585 6.38609 5.76552 6.28903L5.76522 6.28807C5.67105 5.98547 5.60393 5.67155 5.56638 5.34922C5.33827 5.835 4.84464 6.17137 4.27245 6.17137C3.4834 6.17137 2.84375 5.53172 2.84375 4.74267C2.84375 3.95362 3.4834 3.31396 4.27245 3.31396C4.84454 3.31396 5.33809 3.65021 5.56626 4.13586C5.61592 3.70871 5.7174 3.29737 5.86425 2.90829C5.43797 2.53806 4.88138 2.31396 4.27245 2.31396C2.93112 2.31396 1.84375 3.40133 1.84375 4.74267C1.84375 6.084 2.93112 7.17137 4.27245 7.17137Z"
				fill="#0D52FF"
			/>
		</svg>
	)
}

export default MultiLocation
