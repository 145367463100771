import { SVGProps } from 'react'

const UnifiedBilling = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.8326 0.000273437H4.06738C2.88923 0.000273437 1.93416 0.955352 1.93416 2.1335V2.98679H19V2.1335C19 1.56177 18.7706 1.01394 18.3631 0.612884C17.9557 0.211831 17.4043 -0.00887426 16.8326 0.000273437ZM1.89999 4.69336V7.56468C3.8179 6.54678 6.1211 6.57076 8.0174 7.62838C9.91369 8.686 11.1444 10.633 11.2862 12.7996H16.8326C18.0107 12.7996 18.9658 11.8445 18.9658 10.6664V4.69336H1.89999ZM2.29155 12.3334C2.29155 10.8163 3.52447 9.58336 5.04156 9.58336C5.50448 9.58336 5.94448 9.69794 6.32489 9.90419L6.99406 9.23502C6.43031 8.87752 5.76114 8.66669 5.04156 8.66669C3.01572 8.66669 1.37488 10.3075 1.37488 12.3334H-0.00012207L1.83322 14.1667L3.66655 12.3334H2.29155ZM8.25009 10.5L6.41675 12.3333H7.79175C7.79175 13.8504 6.55883 15.0834 5.04175 15.0834C4.57883 15.0834 4.13883 14.9688 3.75841 14.7625L3.08924 15.4317C3.65299 15.7892 4.32216 16 5.04175 16C7.06759 16 8.70842 14.3592 8.70842 12.3333H10.0834L8.25009 10.5Z"
				fill="#406AF2"
			/>
		</svg>
	)
}

export default UnifiedBilling
